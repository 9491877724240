import { CONSTANTS } from '../constants/globals';
import { format } from 'sql-formatter';
import moment from 'moment';

const mappedTables = {
  product: 'items'
};

const additionalTables = {
  product: 'custom'
};

// Define the operator flip mapping object
const OPERATOR_FLIP_MAP = {
  '=': '!=',
  '!=': '=',
  '>': '<=',
  '<': '>=',
  '>=': '<',
  '<=': '>',
  LIKE: 'NOT LIKE',
  'NOT LIKE': 'LIKE',
  IN: 'NOT IN',
  'NOT IN': 'IN'
};

// Helper function to get effective operator
const getEffectiveOperator = (operator, negation) => {
  return negation ? OPERATOR_FLIP_MAP[operator] || operator : operator;
};

const addOtherOptions = (field, temp_query) => {
  const singleConditions = [temp_query];

  if (field.frequency.checked) {
    singleConditions.push(`items.qty ${field.frequency.operator} '${field.frequency.figure}'`);
  }
  if (field.recency.checked) {
    singleConditions.push(
      `items.tenant_created_at ${field.recency.operator} CURRENT_TIMESTAMP - INTERVAL '${field.recency.figure} ${field.recency.type}'`
    );
  }
  let condition_final = temp_query;
  if (singleConditions.length > 1) {
    condition_final = singleConditions.reduce((prev, next) => `${prev} AND ${next}`);
  }
  return `(${condition_final})`;
};

const formatSQL = value => {
  return `'${value.replace(/'/g, "''")}'`;
};

const generateSingleWhereQuery = (field, groupOperator) => {
  let query = '';
  const value =
    typeof field.value == 'number' || typeof field.value === 'object' ? field.value : field.value.replace(/'/g, "''");
  const table = mappedTables[field.table];
  let operator = field.operator;

  if (groupOperator == 'OR' && field.negation) operator = '!=';

  const formattedField = field.field.split('+')[0];

  if ((CONSTANTS[field.table] && CONSTANTS[field.table].includes(formattedField)) || field.table === 'filter') {
    if (typeof field.value === 'object') {
      const date1 = String(moment(value[0]).format('YYYY-MM-DD'));
      const date2 = String(moment(value[1]).format('YYYY-MM-DD'));

      query = `${table}.${formattedField} >= '${date1}' AND ${table}.${formattedField} <= '${date2}'`;
    } else {
      if (field.operator === 'LIKE') {
        query = `${table}.${formattedField} ${operator} '%${value}%'`;
      } else {
        query = `${table}.${formattedField} ${operator} '${value}'`;
      }
    }
  } else {
    query = `${table}.${additionalTables[field.table]}->>'${formattedField}' ${operator} '${value}'`;
  }

  return query;
};

const generateMultipleWhereQuery = (fields, groupOperator) => {
  const groupedField = [];
  let isFilter = false;

  const counter = {};

  fields.forEach(field => {
    if (
      !counter[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ]
    ) {
      counter[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ] = 0;
    }
    counter[
      field.frequency.checked || field.recency.checked
        ? `${field.table}-${field.field}-advanced`
        : `${field.table}-${field.field}`
    ] += 1;
  });

  const composedFields = {};

  fields.forEach(field => {
    if (field.table === 'filter') {
      isFilter = true;
    }
    if (
      counter[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ] > 1
    ) {
      if (!composedFields[`${field.table}-${field.field}`]) {
        composedFields[`${field.table}-${field.field}`] = {
          field: field.field,
          table: field.table,
          values: []
        };
      }
      composedFields[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ].values.push({ value: field.value, operator: field.operator, negation: field.negation });
    } else {
      composedFields[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ] = field;
    }
  });

  Object.keys(composedFields).forEach(cf => {
    const field = composedFields[cf];

    if (field.table === 'filter') {
      isFilter = true;
    }

    if (field.values) {
      let condition = '';

      if (CONSTANTS[field.table] && CONSTANTS[field.table].includes(field.field)) {
        if (field.field.toLowerCase().includes('_dt') || field.field.toLowerCase().includes('date')) {
          const formattedDateRange = field.values.map(dateRange => {
            const date1 = String(moment(dateRange[0]).format('YYYY-MM-DD'));
            const date2 = String(moment(dateRange[1]).format('YYYY-MM-DD'));
            return `SELECT items.item_id FROM items WHERE items.tenant_created_at >= '${date1}' AND items.tenant_created_at <= '${date2}'`;
          });

          condition = `${mappedTables[field.table]}.item_id ${
            field.negation ? 'NOT IN' : 'IN'
          } (${formattedDateRange.join(' UNION ')})`;
        } else {
          if (groupOperator === 'OR') {
            const operatorGroups = field.values.reduce((acc, val) => {
              const effectiveOperator = getEffectiveOperator(val.operator, val.negation);

              if (!acc[effectiveOperator]) {
                acc[effectiveOperator] = [];
              }
              acc[effectiveOperator].push(val.value);
              return acc;
            }, {});

            const conditions = Object.entries(operatorGroups).map(([operator, values]) => {
              switch (operator) {
                case '=':
                  return `${mappedTables[field.table]}.${field.field} IN (${values.map(v => formatSQL(v)).join(', ')})`;
                case '!=':
                  return `${mappedTables[field.table]}.${field.field} NOT IN (${values
                    .map(v => formatSQL(v))
                    .join(', ')})`;
                default:
                  return values
                    .map(v => `${mappedTables[field.table]}.${field.field} ${operator} ${formatSQL(v)}`)
                    .join(' OR ');
              }
            });

            condition = conditions.length > 1 ? `(${conditions.join(' OR ')})` : conditions[0];
          } else {
            const conditions = field.values.map(val => {
              const effectiveOperator = getEffectiveOperator(val.operator, val.negation);
              return `${mappedTables[field.table]}.${field.field} ${effectiveOperator} ${formatSQL(val.value)}`;
            });
            condition = `(${conditions.join(' AND ')})`;
          }
        }
      } else {
        condition = `${mappedTables[field.table]}.${additionalTables[field.table]}->>'${field.field}' ${
          field.negation ? 'NOT IN' : 'IN'
        } (${field.values.map(f => formatSQL(f)).reduce((va, vb) => va + ', ' + vb)})`;
      }

      groupedField.push(condition);
    } else {
      let condition = generateSingleWhereQuery(field, groupOperator);
      const condition_final = addOtherOptions(field, condition);
      groupedField.push(condition_final);
    }
  });

  const condition = groupedField.reduce((a, b) => `${a} OR ${b}`);
  return {
    condition,
    isFilter
  };
};

const addToSymbol = (field, symbolTable) => {
  const locationInSymbolTable = symbolTable[field.table];

  if (!locationInSymbolTable[field.field]) {
    locationInSymbolTable[field.field] = [];
  }
  locationInSymbolTable[field.field].push(field);
};

const createGroup = group => {
  if (Object.keys(group.fields).length == 0) return null;

  if (group.operator === 'OR') {
    const { condition, isFilter } = generateMultipleWhereQuery(Object.values(group.fields), group.operator);
    return {
      groupCondition: condition,
      havingCondition: null,
      isFilter
    };
  } else {
    let symbolNegativeTable = {
      product: {},
      customer: {},
      filter: {},
      sale: {}
    };
    let symbolTable = {
      product: {},
      customer: {},
      filter: {},
      sale: {}
    };
    let symbolCounter = {};

    let isFilter = false;

    // Group the fields together
    const fields = group.fields;

    Object.keys(fields).forEach(fieldKey => {
      const field = fields[fieldKey];
      if (field.table === 'filter') {
        isFilter = true;
      }
      if (field.negation) {
        addToSymbol(field, symbolNegativeTable);
      } else {
        if (field.field.toLowerCase().includes('_dt') || field.field.toLowerCase().includes('date')) {
          const position = Object.keys(symbolTable[field.table]).length;
          addToSymbol({ ...field, field: `${field.field}+${position}` }, symbolTable, symbolCounter);
        } else {
          addToSymbol(field, symbolTable, symbolCounter);
        }
      }
    });

    Object.keys(fields).forEach(fieldKey => {
      const field = fields[fieldKey];

      if (
        symbolTable[field.table][field.field] &&
        symbolTable[field.table][field.field].length > 1 &&
        !field.negation
      ) {
        if (CONSTANTS[field.table] && CONSTANTS[field.table].includes(field.field)) {
          symbolCounter[`COUNT(DISTINCT ${mappedTables[field.table]}.${field.field})`] =
            symbolTable[field.table][field.field].length;
        } else {
          symbolCounter[
            `COUNT(DISTINCT ${mappedTables[field.table]}.${additionalTables[field.table]}->>'${field.field}')`
          ] = symbolTable[field.table][field.field].length;
        }
      }
    });

    // Set the where conditions
    let conditions = [];
    Object.keys(symbolTable).forEach(table => {
      Object.keys(symbolTable[table]).forEach(fieldName => {
        const fields = symbolTable[table][fieldName];
        let inline_group_query = '';
        if (fields.length == 1) {
          const field = fields[0];
          const temp_query = generateSingleWhereQuery(field, group.operator);

          const condition_final = addOtherOptions(field, temp_query);
          inline_group_query = condition_final;
        } else {
          const { condition } = generateMultipleWhereQuery(fields, group.operator);
          inline_group_query = condition;
        }
        conditions.push(inline_group_query);
      });
    });

    // Where conditions
    let groupCondition = '';
    if (conditions.length == 1) {
      groupCondition = `(${conditions[0]})`;
    } else if (conditions.length > 1) {
      groupCondition = conditions.reduce((condition_a, condition_b) => {
        return `(${condition_a}) ${group.operator} (${condition_b})`;
      });
    }

    // Having conditions
    let havingCondition = '';
    if (Object.keys(symbolCounter).length == 1) {
      const condition = Object.keys(symbolCounter)[0];
      havingCondition = `${condition} = ${symbolCounter[condition]}`;
    } else if (Object.keys(symbolCounter).length > 1) {
      havingCondition = Object.keys(symbolCounter).reduce((condition_a, condition_b) => {
        return `${condition_a} = ${symbolCounter[condition_a]} AND ${condition_b} = ${symbolCounter[condition_b]}`;
      });
    }

    // Set the conditions for negations
    let negations = [];
    Object.keys(symbolNegativeTable).forEach(table => {
      if (Object.keys(symbolNegativeTable[table]).length >= 1) {
        Object.keys(symbolNegativeTable[table]).forEach(fieldName => {
          const fields = symbolNegativeTable[table][fieldName];
          let values = '';

          if (
            typeof fields[0] === 'object' &&
            (fields[0].field.toLowerCase().includes('_dt') || fields[0].field.toLowerCase().includes('date'))
          ) {
            if (fields.length > 0) {
              fields.forEach(field => {
                negations.push(
                  `COUNT (CASE WHEN ${mappedTables[table]}.${fieldName} >= '${moment(field.value[0]).format(
                    'YYYY-MM-DD'
                  )}' AND ${mappedTables[table]}.${fieldName} <= '${moment(field.value[1]).format(
                    'YYYY-MM-DD'
                  )}' THEN 1 END) = 0`
                );
              });
            } else {
              negations.push(
                `COUNT (CASE WHEN ${mappedTables[table]}.${fieldName} >= '${moment(fields[0].value[0]).format(
                  'YYYY-MM-DD'
                )}' AND ${mappedTables[table]}.${fieldName} <= '${moment(fields[0].value[1]).format(
                  'YYYY-MM-DD'
                )}' THEN 1 END) = 0`
              );
            }
          } else {
            if (fields.length == 1) {
              if (
                typeof fields[0] === 'object' &&
                (fields[0].field.toLowerCase().includes('_dt') || fields[0].field.toLowerCase().includes('date'))
              ) {
                values = `'${fields[0].value}'`;
              } else {
                values = `'${fields[0].value.replace(/'/g, "''")}'`;
              }
            } else if (fields.length > 1) {
              values = fields.reduce((prev, curr, i) => {
                return i === 0 ? formatSQL(curr.value) : prev + ', ' + formatSQL(curr.value);
              }, '');
            }

            if (CONSTANTS[table] && CONSTANTS[table].includes(fieldName)) {
              negations.push(`COUNT (CASE WHEN ${mappedTables[table]}.${fieldName} IN (${values}) THEN 1 END) = 0`);
            } else {
              negations.push(
                `COUNT (CASE WHEN ${mappedTables[table]}.${additionalTables[table]}->>'${fieldName}' IN (${values}) THEN 1 END) = 0`
              );
            }
          }
        });
      }
    });

    let negationCondition = '';
    if (negations.length == 1) {
      negationCondition = negations[0];
    } else if (negations.length > 1) {
      negationCondition = negations.reduce((a, b) => `${a} ${group.operator} ${b}`);
    }

    return {
      groupCondition,
      havingCondition,
      negationCondition,
      isFilter
    };
  }
};

const HavingConditionNegationCondition = (
  groupCondition,
  havingCondition,
  negationCondition,
  groupOperator,
  isFilter
) => {
  if (groupCondition) {
    if (groupOperator == 'AND') {
      return `
      SELECT a.item_id FROM (
        SELECT DISTINCT items.item_id FROM items
        ${
          isFilter
            ? `
        INNER JOIN item_filters ON item_filters.item_id = items.item_id
        INNER JOIN filter ON filter.id = item_filters.filter_id
        `
            : ''
        }
        WHERE ${groupCondition}
        GROUP BY items.item_id
        HAVING ${havingCondition}
      ) a INNER JOIN (
        SELECT items.item_id FROM items
        ${
          isFilter
            ? `
        INNER JOIN item_filters ON item_filters.item_id = items.item_id
        INNER JOIN filter ON filter.id = item_filters.filter_id
        `
            : ''
        }
        GROUP BY items.item_id
        HAVING
        ${negationCondition}
      ) b
      ON a.item_id = b.item_id
      `;
    } else {
      return `
      SELECT DISTINCT items.item_id FROM items
      ${
        isFilter
          ? `
      INNER JOIN item_filters ON item_filters.item_id = items.item_id
      INNER JOIN filter ON filter.id = item_filters.filter_id
      `
          : ''
      }
      WHERE ${groupCondition}
      GROUP BY items.item_id
      HAVING ${havingCondition}
      UNION
      SELECT items.item_id FROM items
      GROUP BY items.item_id
      HAVING
      ${negationCondition}
      `;
    }
  } else {
    return `
    SELECT items.item_id FROM items
    ${
      isFilter
        ? `
    INNER JOIN item_filters ON item_filters.item_id = items.item_id
    INNER JOIN filter ON filter.id = item_filters.filter_id
    `
        : ''
    }
    GROUP BY items.item_id
    HAVING
    ${negationCondition}
    `;
  }
};

const NotHavingConditionNegationCondition = (groupCondition, negationCondition, groupOperator, isFilter) => {
  if (groupCondition) {
    if (groupOperator == 'AND') {
      return `
      SELECT a.item_id FROM (
        SELECT DISTINCT items.item_id FROM items
        ${
          isFilter
            ? `
        INNER JOIN item_filters ON item_filters.item_id = items.item_id
        INNER JOIN filter ON filter.id = item_filters.filter_id
        `
            : ''
        }
        WHERE ${groupCondition}
      ) a INNER JOIN (
        SELECT items.item_id FROM items
        ${
          isFilter
            ? `
        INNER JOIN item_filters ON item_filters.item_id = items.item_id
        INNER JOIN filter ON filter.id = item_filters.filter_id
        `
            : ''
        }
        GROUP BY items.item_id
        HAVING
        ${negationCondition}
      ) b ON a.item_id = b.item_id
      `;
    } else {
      return `
      SELECT DISTINCT items.item_id FROM items
      ${
        isFilter
          ? `
      INNER JOIN item_filters ON item_filters.item_id = items.item_id
      INNER JOIN filter ON filter.id = item_filters.filter_id
      `
          : ''
      }
      WHERE ${groupCondition}
      UNION
      SELECT items.item_id FROM items
      ${
        isFilter
          ? `
      INNER JOIN item_filters ON item_filters.item_id = items.item_id
      INNER JOIN filter ON filter.id = item_filters.filter_id
      `
          : ''
      }
      GROUP BY items.item_id
      HAVING
      ${negationCondition}
      `;
    }
  } else {
    return `
    SELECT items.item_id FROM items
    ${
      isFilter
        ? `
    INNER JOIN item_filters ON item_filters.item_id = items.item_id
    INNER JOIN filter ON filter.id = item_filters.filter_id
    `
        : ''
    }
    GROUP BY items.item_id
    HAVING
    ${negationCondition}
    `;
  }
};

// The rest of the code will remain the same as before since there are no further references to the 'events' table.

export const getClauses = (groups, filterOperator, parentTable = 'items') => {
  const groupKeys = Object.keys(groups);

  const groupConditions = groupKeys.map((groupKey, index) => {
    const foo = `foo${index}`;
    const group = groups[groupKey];
    const creation = createGroup(group);
    if (!creation) return null;
    const { groupCondition, havingCondition, negationCondition, isFilter } = creation;

    if (havingCondition) {
      if (negationCondition) {
        return `
        SELECT item_id FROM (
          SELECT items.item_id
          FROM items
          WHERE items.item_id IN (${HavingConditionNegationCondition(
            groupCondition,
            havingCondition,
            negationCondition,
            group.operator,
            isFilter
          )})
        ) as ${foo}`;
      } else {
        return `
        SELECT item_id FROM (
        SELECT items.item_id
        FROM items
        ${
          isFilter
            ? `
        INNER JOIN item_filters ON item_filters.item_id = items.item_id
        INNER JOIN filter ON filter.id = item_filters.filter_id
        `
            : ''
        }
        WHERE ${groupCondition}
        ) as ${foo}`;
      }
    } else {
      if (negationCondition) {
        return `
          SELECT item_id FROM (
          SELECT items.item_id
          FROM items
          WHERE items.item_id IN (${NotHavingConditionNegationCondition(
            groupCondition,
            negationCondition,
            group.operator,
            isFilter
          )})
        ) as ${foo}`;
      } else {
        return `
        SELECT item_id FROM (
        SELECT items.item_id
        FROM items
        ${
          isFilter
            ? `
        INNER JOIN item_filters ON item_filters.item_id = items.item_id
        INNER JOIN filter ON filter.id = item_filters.filter_id
        `
            : ''
        }
        WHERE ${groupCondition}
        ) as ${foo}`;
      }
    }
  });

  let query = '';

  const conditions = groupConditions.filter(condition => condition != null);

  if (conditions.length == 1) {
    query = `(${conditions[0]})`;
  } else if (conditions.length > 1) {
    query = conditions.reduce((group_a, group_b) => {
      return `(${group_a} ${filterOperator} ${group_b})`;
    });
  }

  const sql = `${query}`;
  const sqlWithHeaders = `SELECT DISTINCT ${parentTable}.item_id as item_id FROM ${parentTable} WHERE ${parentTable}.item_id IN ${query}`;

  const formattedSQL = format(sql, {
    language: 'postgresql'
  });

  return {
    sql,
    sqlWithHeaders,
    formattedSQL,
    groups,
    conditions
  };
};
