import { $api } from '../../services/api';
import { filterTypes } from '../../constants/types';
import { query } from '../../utils/wrappers/query';
import { CONSTANTS } from '../../constants/globals';

const {
  setGroup,
  unsetGroup,
  setField,
  unsetField,
  setFilterOperator,
  setFilter,
  setGroupOperator,
  setNegation,
  setGroupEditMode,
  setGroupConditions,
  setCurrentlyEditing,
  resetEditing,
  loadFilter,
  populateFields,
  getFilters,
  // deleteFilter,
  flushFilter,
  getCampaignFilters,
  populateValues,
  populateOtherFields,
  setProductsCount,
  setBestSellersWinning,
  // setBestSellersLosing,
  setProductSample,
  loadFormattedFilter,
  setFilterChoice,
  setUsersCount,
  setAbp,
  setAsv,
  setMostVisitedStores,
  setOtherFilters,
  setDemography,
  setTempValue,
  setGroupValue,
  setFilterStatus,
  rePopulateFilter
} = filterTypes.actions;

export const actions = {
  [setTempValue]: async ({ commit }, { value }) => {
    commit(setTempValue, { value });
  },

  [loadFormattedFilter]: async ({ commit }) => {
    return query({
      commit,
      api: $api.filter.formattedFilters.get(),
      options: {
        promise: true,
        mutation: loadFormattedFilter
      }
    });
  },

  [getFilters]: async ({ commit }, { search = null }) => {
    return query({
      commit,
      api: $api.filter.document.get({ search }),
      options: {
        mutation: getFilters
      }
    });
  },

  [getCampaignFilters]: async ({ commit }) => {
    return query({
      commit,
      api: $api.filter.document.get({ search: null }, null, true),
      options: {
        loader: 'filters',
        promise: true
      }
    });
  },

  [setGroup]: ({ commit }) => commit(setGroup),

  [unsetGroup]: ({ commit }, groupProps) => commit(unsetGroup, groupProps),

  [setGroupOperator]: ({ commit }, { filterId, groupId, newOperator }) =>
    commit(setGroupOperator, {
      filterId,
      groupId,
      newOperator
    }),

  [setGroupValue]: ({ commit }, props) => commit(setGroupValue, props),

  [setField]: ({ commit }, fieldProps) => commit(setField, fieldProps),
  [unsetField]: ({ commit }, props) => commit(unsetField, props),
  [setFilterOperator]: ({ commit }, props) => commit(setFilterOperator, props),

  [setProductsCount]: ({ state, commit }) => {
    if (!state.sql_query) {
      state.count = 0;
      return;
    }
    return query({
      commit,
      api: $api.filter.document.post(
        {
          sql: state.sql_query
        },
        'count'
      ),
      options: {
        promise: false,
        mutation: setProductsCount
      }
    });
  },

  /**
   * Get the best sellers winning
   * @param {type} "items.category" | "events.user_id"
   */
  [setBestSellersWinning]: ({ state, commit }, { type, sortOption, soldAtLeast }) => {
    if (!state.sql_query) return null;

    return query({
      commit,
      api: $api.filter.document.post(
        {
          sql: state.sql_query,
          type,
          sortOption,
          soldAtLeast
        },
        'best-sellers/winning'
      ),
      options: {
        promise: false,
        mutation: setBestSellersWinning
      }
    });
  },

  /**
   * Get the best sellers winning
   * @param {type} "items.category" | "events.user_id"
   */
  // [setBestSellersLosing]: ({ state, commit }, { type, sortOption, soldAtLeast }) => {
  //   if (!state.sql_query) return null;

  //   return query({
  //     commit,
  //     api: $api.filter.document.post(
  //       {
  //         sql: state.sql_query,
  //         type,
  //         sortOption,
  //         soldAtLeast
  //       },
  //       'best-sellers/losing'
  //     ),
  //     options: {
  //       promise: false,
  //       mutation: setBestSellersLosing
  //     }
  //   });
  // },

  [setProductSample]: ({ state, commit }) => {
    if (!state.sql_query) {
      state.productSample = [];
      return null;
    }

    return query({
      commit,
      api: $api.filter.document.post(
        {
          sql: state.sql_query
        },
        'product-sample'
      ),
      options: {
        promise: false,
        mutation: setProductSample
      }
    });
  },

  [setFilter]: ({ state, commit }, { name, description, update = false, id = null }) => {
    const config = {
      properties: {
        name,
        description,
        groups: state.groups,
        operator: state.operator,
        sql: state.sql_query
      }
    };

    return query({
      commit,
      api: update ? $api.filter.document.update(config, id) : $api.filter.document.post(config),
      options: {
        promise: true,
        mutation: setFilter
      }
    });
  },

  [setNegation]: ({ commit }, props) => commit(setNegation, props),
  [setGroupEditMode]: ({ commit }, props) => commit(setGroupEditMode, props),
  [setGroupConditions]: ({ commit }, props) => commit(setGroupConditions, props),

  [populateValues]: ({ commit }, { table, field, local, search = null }) => {
    if (table && field) {
      query({
        commit,
        api: $api.filter.populate.get({}, `${table}/${field}/${local}` + `${search ? `?search=${search}` : ''}`),
        options: {
          mutation: populateValues
        }
      });
    }
  },

  [populateOtherFields]: ({ commit }, { table }) => {
    if (!table) return;

    query({
      commit,
      api: $api.filter.populateOtherFields.get({}, table),
      options: {
        mutation: populateOtherFields,
        props: {
          table
        }
      }
    });
  },

  [setCurrentlyEditing]: ({ commit }, { conditions, fieldId, groupId }) => {
    if (!conditions.table && !conditions.field) return;

    let local = false;

    if (conditions.table !== 'filter') {
      // Check if field is not of type "other json"
      local = CONSTANTS[conditions.table].includes(conditions.field);
    } else {
      local = true;
    }

    query({
      commit,
      api: $api.filter.populate.get({}, `${conditions.table}/${conditions.field}/${local}`),
      options: {
        mutation: populateFields
      }
    });

    commit(setCurrentlyEditing, { fieldId, groupId, table: conditions.table });
  },

  [resetEditing]: ({ commit }) => commit(resetEditing),

  [loadFilter]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.filter.document.get({}, id),
      options: {
        loader: loadFilter,
        promise: false,
        mutation: loadFilter
      }
    });
  },

  // [deleteFilter]: async ({ commit }, { id }) => {
  //   return query({
  //     commit,
  //     api: $api.filter.document.delete({}, `${id}`),
  //     options: {
  //       loader: deleteFilter,
  //       promise: true
  //     }
  //   });
  // },

  [flushFilter]: async ({ commit }) => {
    commit(flushFilter);
  },

  [setFilterChoice]: ({ commit }, { value, groupId }) => commit(setFilterChoice, { value, groupId }),

  /** Filter analytics */

  /**
   * Set the population and target size for the overview card
   * @param {string} filterId
   * @returns {query}
   */
  [setUsersCount]: ({ state, commit }) => {
    return query({
      commit,
      api: $api.analytics.filter.overview.post({
        sql: state.sql_query
      }),
      options: {
        promise: true,
        mutation: setUsersCount
      }
    });
  },

  /**
   * Get the average basket price in a filter
   * @param {string} filterId
   * @returns {query}
   */
  [setAbp]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.filter.abp.post({
        sql: state.sql_query
      }),
      options: {
        promise: true,
        mutation: setAbp
      }
    });
  },

  /**
   * Get the average store visits for a filter
   * @param {string} filterId
   * @returns {query}
   */
  [setAsv]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.filter.asv.post({
        sql: state.sql_query
      }),
      options: {
        promise: true,
        mutation: setAsv
      }
    });
  },

  /**
   * Most visited stores for the past 12 months
   * @param {string} filterId
   * @returns {query}
   */
  [setMostVisitedStores]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.filter.mostVisitedStores.post({
        sql: state.sql_query
      }),
      options: {
        promise: true,
        mutation: setMostVisitedStores
      }
    });
  },

  /**
   * Other filters with customers in it
   * @param {string} filterId
   * @returns {query}
   */
  [setOtherFilters]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.filter.setOtherFilters.post({
        sql: state.sql_query
      }),
      options: {
        promise: true,
        mutation: setOtherFilters
      }
    });
  },

  /**
   * Demographic data
   * @param {string} filterId
   * @returns {query}
   */
  [setDemography]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.filter.demography.post({
        sql: state.sql_query
      }),
      options: {
        promise: true,
        mutation: setDemography
      }
    });
  },
  [setFilterStatus]: async ({ commit }, { id, action }) => {
    commit = () => {};
    return query({
      commit,
      api: $api.filter.document.update({ action }, `${id}/status`),
      options: {
        promise: true
      }
    });
  },
  [rePopulateFilter]: async ({ commit }, { id }) => {
    commit = () => {};
    return query({
      commit,
      api: $api.filter.document.update({}, `${id}/re-populate`),
      options: {
        promise: true
      }
    });
  }
};
