import { Auth } from '@aws-amplify/auth';
import axios from 'axios';
import aws_exports from '../../aws-exports';
export class BaseApiService {
  baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000'
      : aws_exports['env'] === 'staging'
      ? 'http://192.168.100.62:4000'
      : 'https://api.dodobird.ai'; //

  // aws_exports.aws_cloud_logic_custom.map((config) => {
  //     if (config.name === 'webAppAPI') {
  //       return config.endpoint
  //     }
  //   })[0]
  route;

  constructor(route) {
    if (!route) throw new Error('route is not provided');
    this.route = route;
  }
  async setAuthHeaders() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`;
  }

  getUrl(id = '') {
    return `${this.baseUrl}/${this.route}${id ? `/${id}` : ''}`;
  }

  handleErrors(err) {
    // Note: here you may want to add your errors handling
    console.log({ message: 'Errors is handled here', err });
  }
}
