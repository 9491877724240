import { campaignTypes } from '../../constants/types';
const {
  setCampaignDetails,
  updateCampaignValue,
  loadCampaign,
  flushCampaign,
  getAPIcampaigns,
  saveCSV,
  updateCampaignValueA,
  updateCampaignValueB,
  updateSelectedDateAndTime,
  setCheckDefaultCampaignSettings
} = campaignTypes.actions;
import Vue from 'vue';
import { getDefaultState } from './state';

export const mutations = {
  [saveCSV]: (state, { file }) => {
    Vue.set(state, 'file', file);
  },

  [getAPIcampaigns]: (state, { response }) => {
    const campaigns = response.data.value;
    const formatted = campaigns.map(c => ({
      id: c.id,
      encrypted_id: c.encrypted_id,
      name: c.name,
      channel: c.channel,
      objective: 'store visit',
      ab: c.ab,
      status: c.status,
      created_at: c.created_at
    }));
    if (campaigns) {
      Vue.set(state, 'campaigns', formatted);
    }
  },

  [flushCampaign]: state => {
    Object.assign(state, getDefaultState());
  },

  [setCampaignDetails]: (state, payload) => {
    const details = { ...payload };
    Vue.set(state, 'campaignDetails', details);
  },

  [updateCampaignValue]: (state, { field, value }) => {
    Vue.set(state.campaignDetails, field, value);
  },

  [updateCampaignValueA]: (state, { field, value }) => {
    Vue.set(state.groupA, field, value);
  },

  [updateCampaignValueB]: (state, { field, value }) => {
    Vue.set(state.groupB, field, value);
  },

  [loadCampaign]: (state, { response }) => {
    const campaign = response.data.value;

    if (campaign) {
      const details = {
        name: campaign.name,
        numericFilters: campaign.numeric_filters,
        template: campaign.template,
        segments: campaign.segmentIds,
        includedFilters: campaign.includedFilterIds,
        excludedFilters: campaign.excludedFilterIds,
        tags: campaign.tags,
        channel: campaign.channel,
        type: campaign.type,
        manualType: campaign.manual_type,
        ab: campaign.ab,
        zohoSender: campaign.zoho_sender,
        mailchimpSender: campaign.mailchimp_sender,
        recommendations_id: campaign.batch_recommendation_id || 'latest',
        ab_distribution: campaign.ab_distribution,
        ab_objective: campaign.ab_objective,
        server_url: campaign.server_url || null,
        absolutePositioningProductIds: campaign.absolute_positioning_product_ids,
        fromEmailAddress: campaign.from_email_address,
        subject: campaign.email_subject,
        objective: campaign.objective,
        newCustomersRecommendation: campaign.new_customers_recommendation_type,
        createdAt: campaign.created_at,
        status: campaign.status
      };

      Vue.set(state, 'campaignDetails', details);
    }
  },

  [updateSelectedDateAndTime]: (state, { date }) => {
    Vue.set(state, 'scheduledDateAndTime', date);
  },
  [setCheckDefaultCampaignSettings]: (state, { checked }) => {
    Vue.set(state, 'checkDefaultCampaignSettings', checked);
  }
};
