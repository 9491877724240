import { $api } from '../../services/api';

import { s3Types } from '../../constants/types/s3';

import { query } from '../../utils/wrappers/query';

const { getPresignedURL, putFileS3, getFileS3ObjectUrl } = s3Types.actions;

export const actions = {
  [getPresignedURL]: async ({ commit }, { filename }) => {
    return query({
      commit,
      api: $api.storage.document.getPresignedUrl(filename),
      options: {
        mutation: getPresignedURL
      }
    });
  },
  [putFileS3]: async (_commit, { presignedS3Url, file, type }) => {
    return $api.storage.document.putFileToS3(presignedS3Url, file, type);
  },

  [getFileS3ObjectUrl]: async ({ commit }, { filename }) => {
    return query({
      commit,
      api: $api.storage.document.getFileS3ObjectUrl(filename),
      options: {
        mutation: getFileS3ObjectUrl,
        promise: false
      }
    });
  }
};
